const reverseFirst = document.querySelector("#reverse-first");
const reverseSecond = document.querySelector("#reverse-second");
const tabContent = document.querySelectorAll(".l-tab-item");
const newsTabBtn = document.querySelectorAll(".l-tab-btn");
console.log('module');

function mainSwiperBanner() {
  new Swiper(".l-main-swiper", {
    loop: true,
    navigation: {
      nextEl: ".l-main-swiper__btn-next",
      prevEl: ".l-main-swiper__btn-prev",
    },
    autoplay: {
      // delay: 3500,
    },
    pagination: {
      el: ".l-main-swiper__pagination",
    },
  });
}

function mainCardSwiper() {
  new Swiper(".l-card-content__swiper ", {
    sliderPerView: "auto",
    // sliderPerGroup: 1,
    centeredSlides: false,
    width: 310,
    initialSlide: 0,
    watchOverflow: true,
    touchReleaseOnEdges: false,
    freemode: true,
    spaceBetween: 20,
    breakpoints: {
      1430: {
        enabled: false,
      },
      768: {
        width: 310,
      },
      560: {
        width: 290,
        spaceBetween: 20,
      },
      470: {
        width: 270,
      },
      340: {
        width: 200,
      },
      320: {
        width: 140,
        spaceBetween: 10,
      }
    },
  });
}

function reverseSlider() {
  new Swiper(".l-reverse-swiper", {
    // spaceBetween: 20,
    sliderPerView: "auto",
    // loopedSlides: 1,
    // loop: true,
    breakpoints: {
      640: {
        enabled: false,
      },
    },
  });
}

function reverseEl(el) {
  let btnTarget = el.currentTarget;
  if (btnTarget.classList[0] == "l-reverse-btn-next") {
    if (reverseFirst.classList.length == 3) {
      reverseFirst.classList.remove("reverse-first");
      reverseSecond.classList.remove("reverse-second");
    } else {
      reverseFirst.classList.add("reverse-first");
      reverseSecond.classList.add("reverse-second");
    }
  }
  if (btnTarget.classList[0] == "l-reverse-btn-prev") {
    if (reverseSecond.classList.length == 3) {
      reverseFirst.classList.remove("reverse-first");
      reverseSecond.classList.remove("reverse-second");
    } else {
      reverseFirst.classList.add("reverse-first");
      reverseSecond.classList.add("reverse-second");
    }
  }
}

function openTab(el) {
  let btnTarget = el.currentTarget;
  let tab = btnTarget.dataset.tab;

  tabContent.forEach((el) => {
    el.classList.remove("active-tab");
  });
  newsTabBtn.forEach((el) => {
    el.classList.remove("active-tab-btn");
  });

  document.querySelector("#" + tab).classList.add("active-tab");
  btnTarget.classList.add("active-tab-btn");
}

function openFade(el) {
  let btnTarget = el.currentTarget;
  if (btnTarget.classList.length == 1) {
    btnTarget.children[0].children[0].classList.add("active");
    btnTarget.classList.add("active-fade");
  } else {
    btnTarget.classList.remove("active-fade");
    btnTarget.children[0].children[0].classList.remove("active");
  }
}



export {
  mainCardSwiper,
  mainSwiperBanner,
  reverseSlider,
  reverseEl,
  openTab,
  openFade,
};
